class Config {
    constructor() {
        // this.apiUrl = 'https://localhost/api/';
        this.apiUrl = 'https://api.wellride.pl/api/';
        this.appUrl = 'https://app.wellride.pl/';
    }
}

const config = new Config();

export default config;
