import Tool from './tools';

export default class Circle extends Tool {
    constructor() {
        super();
        this.name = "circle";
        this._draw = false;
        this._startX = 0;
        this._startY = 0;
    }

    drawFigure(x, y, ctx, toolProp) {
        ctx.save();
        ctx.lineWidth = toolProp.size;
        ctx.lineCap = "round";
        ctx.strokeStyle = toolProp.color;
        ctx.beginPath();

        const minX = Math.min(this._startX, x);
        const minY = Math.min(this._startY, y);

        const maxX = Math.max(this._startX, x);
        const maxY = Math.max(this._startY, y);

        const middleX = minX + ((maxX - minX) / 2);
        const middleY = minY + ((maxY - minY) / 2);

        const rX = (maxX - minX) / 2;
        const rY = (maxY - minY) / 2;

        ctx.ellipse(middleX, middleY, rX, rY, 0, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.restore();
    }

    drawPointer(x, y, ctx, toolProp) {
        ctx.save();
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        ctx.strokeStyle = toolProp.color;
        ctx.globalAlpha = 1;
        ctx.beginPath();
        ctx.arc(x, y, toolProp.size/2-1, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.restore();
    }

    onMouseMove(x, y, ctx1, ctx2, toolProp) {
        ctx2.clearRect(0, 0, ctx2.canvas.width, ctx2.canvas.height);

        if (!this._draw) {
            this.drawPointer(x, y, ctx2, toolProp);
        } else {
            this.drawFigure(x, y, ctx2, toolProp);
        }

    }

    onMouseUp(x, y, ctx1, ctx2, toolProp) {
        if (this._draw) {
            this.drawFigure(x, y, ctx1, toolProp);
        }
        this._draw = false;
    }

    onMouseDown(x, y, ctx1, ctx2, toolProp) {
        if (!this._draw) {
            this._draw = true;
            this._startX = x;
            this._startY = y;
        }
    }
}
