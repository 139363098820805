import videoPlayer from './videoPlayer';
import board from './painting/board';
import makeTool from './painting/makeTool';
import emiter from "./painting/signalEmiter";
import toolboxGui from "./painting/toolbox_gui";
import videoRecorder from "./videoRecorder";
import config from "./config";
import axios from "axios";

class App {
    constructor() {
        this.setDefaultBoard();
        this.loadData();
        this.setListeners();
    }

    setDefaultBoard() {
        board.setTool(makeTool('brush'));
        board.setColor('red');
        board.setSize(5);
    }

    setListeners() {
        document.querySelector('#video-recorder-back-to-app').addEventListener('click', e => {
            window.location.href = config.appUrl + 'video/list';
        });
    }

    loadData() {
        const uuid = this.getUuidFromUrl();
        if (uuid === null) {
            document.querySelector('#loader').classList.add('hidden');
            document.querySelector('#full_error').classList.remove('hidden');
            document.querySelector('#full_error').classList.add('video-not-found');
        }

        const response2 =
            fetch(config.apiUrl +'video_analysis/video_analysis/' + uuid, {
                method: 'GET',
            }).then(response => {
                if(response.status === 200) {
                    return response.json();
                } else {
                    document.querySelector('#loader').classList.add('hidden');
                    document.querySelector('#full_error').classList.remove('hidden');
                    document.querySelector('#full_error').classList.add('video-not-found');

                    return null;
                }
            }).then(data => {
                if(data !== null) {
                    if (data.status !== 'done') {
                        this.loadVideo(uuid);

                        document.querySelector('.video-title').innerHTML = data.title;
                        document.querySelector('.video-author').innerHTML = data.client_email;
                        document.querySelector('.video-description-content').innerHTML = data.video_comment !== '' ? data.video_comment : 'Brak komentarza';
                        document.querySelector('#video-analysis-uuid').value = data.video_analysis_uuid;
                    } else {
                        document.querySelector('#loader').classList.add('hidden');
                        document.querySelector('#full_error').classList.remove('hidden');
                        document.querySelector('#full_error').classList.add('analysis-is-done');
                    }
                }
            }).catch(error => console.log(error));
    }

    getUuidFromUrl() {
        const url = window.location.href;

        const regex = /\/make_analysis\/(\w+-\w+-\w+-\w+-\w+)\//;
        const match = url.match(regex);
        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    loadVideo(uuid) {
        const videoUrl = config.apiUrl + "video_analysis/video_analysis/" + uuid + "/video.mp4";

        axios({
            url: videoUrl,
            method: 'GET',
            responseType: 'arraybuffer',
            onDownloadProgress: function(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                document.querySelector('#loader .progress-bar').innerHTML = percentCompleted + '%';
            }
        }).then(function(response) {
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const video = document.querySelector("#video_section video source");
            video.src = URL.createObjectURL(blob);
            videoPlayer.initVideoPlayer();

            document.querySelector('#loader').classList.add('hidden');
        }).catch(function(error) {
            alert('Wystąpił błąd :(');
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    new App();
});
