import videojs from 'video.js';
import 'videojs-hotkeys';
import videoRecorder from "./videoRecorder";

class VideoPlayer {
    initVideoPlayer() {
        this.videoPlayer = videojs('video_1', {
            controls: true,
            autoplay: false,
            preload: 'auto',
            muted: true,
            playbackRates: [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2],
            inactivityTimeout: 0,
            controlBar: {
                fullscreenToggle: false,
                pictureInPictureToggle: false
            },
            fill: true,
            userActions: {
                doubleClick: (event) => {
                    this.pause();
                }
            },
            plugins: {
                hotkeys: {
                    volumeStep: 0.1,
                    seekStep: 0.05,
                    enableModifiersForNumbers: false,
                    enableFullscreen: false,
                    captureDocumentHotkeys: true,
                    documentHotkeysFocusElementFilter: () => !videoRecorder.sendRecordDialog.isOpen
                }
            }
        });
    }
}

const videoPlayer = new VideoPlayer();

export default videoPlayer;
