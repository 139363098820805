class Stopwatch {
    constructor(query) {
        this.timerContainer = document.querySelector(query);
        this.timeSpan = this.timerContainer.querySelector('.timer-time');
        this.timer = false;
        this.startRecordTime = 0;
        this.recordingDuration = 0;
    }

    startTimer() {
        stopwatch.timer = true;
        stopwatch.showTime();
        stopwatch.timerContainer.classList.add('pulse');
        stopwatch.startRecordTime = performance.now();
    }

    stopTimer() {
        if (stopwatch.timer) {
            stopwatch.recordingDuration += performance.now() - stopwatch.startRecordTime;
        }
        stopwatch.timer = false;
        stopwatch.timerContainer.classList.remove('pulse');
    }

    resetTimer() {
        stopwatch.timer = false;
        stopwatch.timerContainer.classList.remove('pulse');
        stopwatch.recordingDuration = 0
        stopwatch.startRecordTime = 0
    }

    showTime() {
        if (stopwatch.timer) {
            const currentDuration = stopwatch.recordingDuration + (performance.now() - stopwatch.startRecordTime);
            stopwatch.timeSpan.innerHTML = stopwatch.milisecondsToTime(currentDuration);
        }

        setTimeout(stopwatch.showTime.bind(stopwatch), 200);
    }

    milisecondsToTime(duration) {
        let seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    }
}

const stopwatch = new Stopwatch('.video-recorder-timer');

export default stopwatch;
