import emiter from './signalEmiter';
import makeTool from './makeTool';
import board from './board';

class ToolboxGui {
    constructor(query) {
        this.cnt = null;

        this.generateHTML(query);
        this.bindEvents();
    }

    generateHTML(query) {
        const icons = {
            'brush': `<span class="material-icons-outlined">brush</span>`,
            'line': `<span class="material-icons-outlined">horizontal_rule</span>`,
            'rectangle': `<span class="material-icons-outlined">square</span>`,
            'circle': `<span class="material-icons-outlined">circle</span>`,
        };

        const tools = [
            'brush',
            'line',
            'rectangle',
            'circle'
        ];

        this.cnt = document.createElement('div');
        this.cnt.classList.add('toolbox-gui');

        this.cnt.innerHTML = `
            <div class='toolbox-gui-color'>
                <span class='toolbox-gui-color__element'></span>
                <input type="color" id="toolbox-gui-color-input" value="#FF0000">
            </div>
            <ul class='toolbox-gui-tools'>
                ${tools.map(tool => {
                    return `<li class='toolbox-gui-tools__element toolbox-gui-tools__painting-tool' data-tool='${tool}'>${icons[tool]}</li>`
                }).join('')}
                <li class='toolbox-gui-tools__element' id="toolbox-gui-tools__clear-all">
                    <span class="material-icons-outlined">delete</span>
                </li>
                <li class='toolbox-gui-tools__element toolbox-gui-tools__painting-tool' data-tool='null' id="toolbox-gui-tools__only-cursor">
                    <span class="material-icons-outlined">pan_tool_alt</span>
                </li>
            </ul>
        `;
        document.querySelector(query).append(this.cnt);
    }

    updateToolsList(toolName) {
        this.cnt.querySelectorAll('.toolbox-gui-tools__element').forEach(el => {
            el.classList.remove('is-active');
        });

        this.cnt.querySelector(`.toolbox-gui-tools__element[data-tool='${toolName}']`).classList.add('is-active');
    }

    updateColor(color) {
        this.cnt.querySelector('.toolbox-gui-color__element').style.background = color;
    }

    bindEvents() {
        this.cnt.querySelector('#toolbox-gui-color-input').addEventListener('change', e => {
            board.setColor(e.target.value);
        })

        this.cnt.querySelectorAll('.toolbox-gui-tools__painting-tool').forEach(el => {
            el.addEventListener('click', e => {
                const tool = makeTool(el.dataset.tool);
                board.setTool(tool);
            })
        })

        this.cnt.querySelector('#toolbox-gui-tools__clear-all').addEventListener('click', e => {
            board.clear();
        })

        this.cnt.querySelector('#toolbox-gui-tools__only-cursor').addEventListener('click', e => {
            board.addCursor();
        })

        emiter.changeTool.on(tool => {
            this.updateToolsList(tool.name);
        })

        emiter.changeColor.on(color => {
            this.updateColor(color);
        })
    }
}

const toolboxGui = new ToolboxGui('#toolbox_section');
export default toolboxGui;