import Tool from './tools';

export default class Line extends Tool {
    constructor() {
        super();
        this.name = 'rectangle';
        this._draw = false;
        this._startX = 0;
        this._startY = 0;
    }

    drawFigure(x, y, ctx, toolProp) {
        ctx.lineWidth = toolProp.size;
        ctx.lineCap = 'round';
        ctx.strokeStyle = toolProp.color;
        ctx.beginPath();
        ctx.strokeRect(this._startX, this._startY, x - this._startX, y - this._startY);
        ctx.restore();
    }

    drawPointer(x, y, ctx, toolProp) {
        ctx.save();
        ctx.lineWidth = 2;
        ctx.lineCap = 'round';
        ctx.strokeStyle = toolProp.color;
        ctx.globalAlpha = 1;
        ctx.beginPath();
        ctx.strokeRect(x - toolProp.size / 2, y - toolProp.size / 2, toolProp.size, toolProp.size);
        ctx.restore();
    }

    onMouseMove(x, y, ctx1, ctx2, toolProp) {
        ctx2.clearRect(0, 0, ctx2.canvas.width, ctx2.canvas.height);

        if (!this._draw) {
            this.drawPointer(x, y, ctx2, toolProp);
        } else {
            this.drawFigure(x, y, ctx2, toolProp);
        }

    }

    onMouseUp(x, y, ctx1, ctx2, toolProp) {
        if (this._draw) {
            this.drawFigure(x, y, ctx1, toolProp);
        }
        this._draw = false;
    }

    onMouseDown(x, y, ctx1, ctx2, toolProp) {
        if (!this._draw) {
            this._draw = true;
            this._startX = x;
            this._startY = y;
        }
    }
}