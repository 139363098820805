import stopwatch from "./stopwatch";
import {MDCDialog} from '@material/dialog';
import {MDCTextField} from '@material/textfield';
import fixWebmDuration from 'webm-duration-fix';
import config from "./config";

class VideoRecorder {
    constructor() {
        this.buttonsContainer = document.querySelector('.video-recorder-toolbox');
        this.recordedVideo = document.querySelector('#video-recorder-analysis-preview');
        this.startButton = document.querySelector('#video-recorder-start-btn');
        this.pauseButton = document.querySelector('#video-recorder-pause-btn');
        this.stopButton = document.querySelector('#video-recorder-stop-btn');
        this.micButton = document.querySelector('#video-recorder-mic-btn');
        this.deleteButton = document.querySelector('#video-recorder-delete-btn');
        this.confirmDeleteButton = document.querySelector('#video-recorder-confirm-delete-btn');
        this.confirmSendButton = document.querySelector('#video-recorder-confirm-send-btn');
        this.confirmRenewButton = document.querySelector('#video-recorder-confirm-renew-btn');
        this.sendingAnalysisProgressBar = document.querySelector('#video-recorder-sending-analysis .progress-bar');

        this.deleteRecordDialog = new MDCDialog(document.querySelector('#video-recorder-confirm-delete'));
        this.sendRecordDialog = new MDCDialog(document.querySelector('#video-recorder-confirm-send'));
        this.renewDialog = new MDCDialog(document.querySelector('#video-recorder-confirm-renew'));
        this.commentTextarea = new MDCTextField(document.querySelector('#video-recorder-finish-comment-text-field'));

        this.sendingAnalysisDialog = new MDCDialog(document.querySelector('#video-recorder-sending-analysis'));
        this.thanksForAnalysisDialog = new MDCDialog(document.querySelector('#video-recorder-thanks-for-analysis'));
        this.sendingErrorDialog = new MDCDialog(document.querySelector('#video-recorder-sending-error'));

        this.recordingStatus = 0;
        this.stream = null;
        this.audio = null;
        this.mixedStream = null;
        this.chunks = [];
        this.recorder = null;
        this.recordWithAudio = true;

        this.recordingRect = null;

        this.bindEvents();
    }

    bindEvents() {
        this.startButton.addEventListener('click', e => {
            if (this.recordingStatus === 0) {
                if (this.recordedVideo.src) {
                    this.renewDialog.open();
                } else {
                    this.startRecording();
                }
            } else {
                this.resumeRecording();
            }
        });

        this.pauseButton.addEventListener('click', e => {
            this.pauseRecording();
        });

        this.stopButton.addEventListener('click', e => {
            if (this.recordedVideo.src) {
                this.sendRecordDialog.open();
            } else {
                this.stopRecording();
            }
            document.activeElement.blur();
        });

        this.micButton.addEventListener('click', e => {
            this.toggleMic();
        });

        this.deleteButton.addEventListener('click', e => {
            this.deleteRecordDialog.open();
        });

        this.confirmDeleteButton.addEventListener('click', e => {
            this.deleteRecord();
        });

        this.confirmSendButton.addEventListener('click', e => {
            this.sendRecord();
        });

        this.confirmRenewButton.addEventListener('click', e => {
            this.renewRecord();
        });
    }

    async startRecording() {
        await this.setupStream();

        if (this.stream && this.audio) {
            this.buttonsContainer.classList.add('recording-started');
            this.buttonsContainer.classList.remove('recording-stopped');

            this.mixedStream = this.recordWithAudio
                ? new MediaStream([...this.stream.getTracks(), ...this.audio.getTracks()])
                : new MediaStream([...this.stream.getTracks()]);
            this.recorder = new MediaRecorder(this.mixedStream);
            this.recorder.ondataavailable = this.handleDataAvailable;
            this.recorder.onstop = this.handleStop;
            this.recorder.start(1000);

            this.recordingStatus = 1;
            this.micButton.disabled = true;
            this.stopButton.disabled = false;

            stopwatch.resetTimer();
            stopwatch.startTimer();

            this.recordingRect = document.querySelector('#video_section').getBoundingClientRect();
        } else {
            console.warn('No stream available.');
        }
    }

    async setupStream() {
        try {
            this.stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                preferCurrentTab: true
            });

            this.audio = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
            });

            this.stream.getVideoTracks()[0].onended = function () {
                videoRecorder.stopRecording();
            };
        } catch (err) {
            console.error(err)
        }
    }

    handleDataAvailable(e) {
        videoRecorder.chunks.push(e.data);
    }

    handleStop(e) {
        if (videoRecorder.chunks !== []) {
            videoRecorder.recoordedVideoBlob = new Blob(videoRecorder.chunks, {'type': 'video/mp4'});
            videoRecorder.chunks = [];

            fixWebmDuration(videoRecorder.recoordedVideoBlob)
                .then((blob) => {
                    videoRecorder.recoordedVideoBlob = blob;
                    videoRecorder.recordedVideo.src = URL.createObjectURL(blob);
                    videoRecorder.recordedVideo.load();
                });
        }

        videoRecorder.stream.getTracks().forEach((track) => track.stop());
        videoRecorder.audio.getTracks().forEach((track) => track.stop());
    }

    pauseRecording() {
        this.recorder.pause();
        stopwatch.stopTimer();

        this.buttonsContainer.classList.remove('recording-started');
        this.buttonsContainer.classList.add('recording-stopped');
    }

    resumeRecording() {
        this.recorder.resume();
        stopwatch.startTimer();

        this.buttonsContainer.classList.add('recording-started');
        this.buttonsContainer.classList.remove('recording-stopped');
    }

    stopRecording() {
        stopwatch.stopTimer();
        this.recordingStatus = 0;
        this.micButton.disabled = false;
        this.recorder.stop();
        this.sendRecordDialog.open();

        this.buttonsContainer.classList.remove('recording-started');
        this.buttonsContainer.classList.add('recording-stopped');
    }

    deleteRecord() {
        this.recordingStatus = 0;
        this.chunks = [];
        this.recorder.stop();
        stopwatch.resetTimer();
        this.micButton.disabled = false;
        this.stopButton.disabled = true;
        this.recordedVideo.removeAttribute('src');
        this.buttonsContainer.classList.remove('recording-started');
        this.buttonsContainer.classList.remove('recording-stopped');
    }

    toggleMic() {
        if (this.recordWithAudio) {
            this.micButton.classList.remove('active-mic');
            this.recordWithAudio = false;
        } else {
            this.micButton.classList.add('active-mic');
            this.recordWithAudio = true;
        }
    }

    sendRecord() {
        this.sendingAnalysisDialog.open();

        const formData = new FormData();
        formData.append('video', videoRecorder.recoordedVideoBlob, 'video.mp4');
        formData.append('comment', videoRecorder.commentTextarea.value);
        formData.append('videoAnalysisUuid', document.querySelector('#video-analysis-uuid').value);
        formData.append('startPointX', videoRecorder.recordingRect.left.toString());
        formData.append('startPointY', (videoRecorder.recordingRect.top + 1).toString());
        formData.append('width', videoRecorder.recordingRect.width.toString());
        formData.append('height', videoRecorder.recordingRect.height.toString());

        const xhr = new XMLHttpRequest();
        xhr.open('POST', config.apiUrl + 'video_analysis/analysis');

        xhr.upload.addEventListener('progress', (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            this.sendingAnalysisProgressBar.innerHTML = `${progress}%`;
        });

        xhr.addEventListener('load', (event) => {
            if (xhr.status >= 200 && xhr.status < 300) {
                this.sendingAnalysisDialog.close();
                this.thanksForAnalysisDialog.open();
                setTimeout(() => {
                    window.location.href = config.appUrl + 'video/list';
                }, 3000);
            } else {
                this.sendingAnalysisDialog.close();
                this.sendingErrorDialog.open();
            }
        });

        xhr.addEventListener('error', (event) => {
            this.sendingErrorDialog.open();
        });

        xhr.send(formData);
    }

    renewRecord() {
        this.recordingStatus = 0;
        this.chunks = [];
        stopwatch.resetTimer();
        this.micButton.disabled = true;
        this.stopButton.disabled = true;
        this.recordedVideo.removeAttribute('src');
        this.startRecording();
    }

    patchBlob(blob, duration) {
        return new Promise(resolve => {
            fixWebmDuration(blob, duration, newBlob => resolve(newBlob));
        });
    }
}

const videoRecorder = new VideoRecorder();

export default videoRecorder;
