import emiter from './signalEmiter';

function createCanvas(id, parentElement) {
    const canvas = document.createElement('canvas');
    canvas.width = parentElement.offsetWidth;
    canvas.height = parentElement.offsetHeight;
    canvas.id = id;
    return canvas;
}

class Board {
    constructor(query) {
        this.container = document.querySelector(query);

        this.canvas1 = createCanvas('mainCanvas', this.container);
        this.canvas2 = createCanvas('secondaryCanvas', this.container);

        this.container.append(this.canvas1);
        this.container.append(this.canvas2);

        this.ctx1 = this.canvas1.getContext('2d');
        this.ctx2 = this.canvas2.getContext('2d');

        this.mouse = {x: 0, y: 0}
        this.currentTool = null;
        this.toolParams = {
            color: 'red',
            size: 10
        }

        this.bindEvents();
    }

    bindEvents() {
        document.addEventListener('mousemove', e => {
            this.mouse.x = e.pageX;
            this.mouse.y = e.pageY - 41;

            if (this.currentTool) {
                this.currentTool.onMouseMove(this.mouse.x, this.mouse.y, this.ctx1, this.ctx2, this.toolParams)
            }
        });

        this.canvas1.addEventListener('mousedown', e => {
            if (this.currentTool) {
                this.currentTool.onMouseDown(e.offsetX, e.offsetY, this.ctx1, this.ctx2, this.toolParams)
            }
        });

        this.canvas1.addEventListener('mouseup', e => {
            if (this.currentTool) {
                this.currentTool.onMouseUp(e.offsetX, e.offsetY, this.ctx1, this.ctx2, this.toolParams)
            }
        });

        this.canvas1.addEventListener('mouseleave', e => {
            if (this.currentTool) {
                this.currentTool.onMouseUp(e.offsetX, e.offsetY, this.ctx1, this.ctx2, this.toolParams)
            }
        });

        window.addEventListener('resize', e => {
            let rect = this.container.getBoundingClientRect();

            this.canvas1.height = rect.height;
            this.canvas1.width = rect.width;

            this.canvas2.height = rect.height;
            this.canvas2.width = rect.width;
        });
    }

    setColor(color) {
        this.toolParams.color = color;
        this.currentTool.onMouseMove(this.mouse.x, this.mouse.y, this.ctx1, this.ctx2, this.toolParams);
        emiter.changeColor.emit(color);
    }

    setSize(size) {
        this.toolParams.size = size;
        this.currentTool.onMouseMove(this.mouse.x, this.mouse.y, this.ctx1, this.ctx2, this.toolParams);
        emiter.changeSize.emit(size);
    }

    setTool(tool) {
        this.currentTool = tool;
        this.currentTool.onMouseMove(this.mouse.x, this.mouse.y, this.ctx1, this.ctx2, this.toolParams);
        emiter.changeTool.emit(tool);
        this.removeCursor();
    }

    clear() {
        this.ctx1.clearRect(0, 0, this.canvas1.width, this.canvas1.height);
        this.ctx1.restore();

        this.ctx2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
        this.ctx2.restore();
    }

    addCursor() {
        this.canvas1.classList.add('with-cursor');
    }

    removeCursor() {
        this.canvas1.classList.remove('with-cursor');
    }
}

const board = new Board('#canvas_parent');

export default board;